import { template as template_b61ce1e28ccc4de59b940497e8db7f85 } from "@ember/template-compiler";
const FKLabel = template_b61ce1e28ccc4de59b940497e8db7f85(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
